<template>
  <div class="page-customer-quotes">
    <v-row justify="space-between" align="center">
      <v-col>
        <v-row>
          <v-col>
            <span class="text-wrap display-1">{{ $t('Quotes') }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-4"></v-divider>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <DataTableFilter
              width-of-filters-titles="1"
              ref="dataTableFilter"
              :filters="filterObject"
              @update="updateFilter"
            ></DataTableFilter>
          </v-col>
          <v-col class="col-12 col-md-4">
            <v-text-field
              v-model="search"
              append-icon="fas fa-search"
              :label="$t('Search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          width="100%"
          :headers="headers"
          :items="customerQuotes"
          :search="search"
          :custom-filter="customFilter"
          sort-by="created"
          :sort-desc="true"
          :footer-props="{
            itemsPerPageOptions: [15, 50, 100, -1]
          }"
          @click:row="goToPageItem($event)"
          class="main-data-table"
        >
          <template v-slot:[`item.status`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
              :title="item.status !== 'CLOSED' ? $t(item.status) : $t('ACCEPTED')"
            >
              <template v-if="item.status === 'LOCKED' && item.dispatch.status === 'NEW'">
                <v-icon small color="info">
                  fas fa-unlock
                </v-icon>
              </template>
              <template v-else-if="item.status === 'LOCKED' && item.dispatch.status === 'READY'">
                <v-icon small color="warning">
                  fas fa-clock
                </v-icon>
              </template>
              <template v-else-if="item.status === 'DECLINED'">
                <v-icon small color="error">
                  fas fa-times-circle
                </v-icon>
              </template>
              <template v-else-if="item.status === 'ACCEPTED' || item.status === 'CLOSED'">
                <v-icon small color="success">
                  fas fa-check-circle
                </v-icon>
              </template>
            </router-link>
          </template>
          <template v-slot:[`item.quoteNumber`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              {{ item.quoteNumber }}
            </router-link>
          </template>
          <template v-slot:[`item.brand.name`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              {{ item.brand.name }}
            </router-link>
          </template>
          <template v-slot:[`item.dispatch.purchase.totalAmountWithoutVAT`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              <span v-if="item.billing">
                {{ $n(item.dispatch.purchase.totalAmountWithoutVAT, 'currency', $userLocale) }}
              </span>
              <span v-else class="font-weight-light">
                {{ $t('NotApplicable') }}
              </span>
            </router-link>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              {{ $d(new Date(item.created), 'long', $userLocale) }}
            </router-link>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <router-link
              :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
              class="text-decoration-none"
            >
              <v-btn
                :to="{name: 'Quote', params: { quoteUUID: `${item._id}`}}"
                class="primary"
                :title="$t('ViewQuote')"
              >
                <v-icon small>
                  far fa-eye
                </v-icon>
              </v-btn>
            </router-link>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable, DataTableFilter } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageCustomerQuotes.i18n.json');

export default {
  name: 'PageCustomerQuotes',
  components: {
    DataTableFilter
  },
  props: {
    /**
     * Customer
     */
    customer: {
      type: Object,
      default: null
    },
    /**
     * Users
     */
    customerUsers: {
      type: Array,
      default: null
    },
    /**
     * Quotes
     */
    customerQuotes: {
      type: Array,
      default: null
    },
    /**
     * Orders
     */
    customerOrders: {
      type: Array,
      default: null
    },
    /**
     * Parts
     */
    customerParts: {
      type: Array,
      default: null
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headers: [],
      search: '',
      filterObject: {},
      statusFilter: [],
      typeFilter: []
    };
  },
  mounted() {
    this.reinitializeFilterCount();
  },
  created() {
    this.filterObject = {
      status: [
        {
          name: this.$t('QuoteLocked'),
          value: 'LOCKED', 
          iconColor: 'info',
          icon: 'fas fa-unlock',
          number: 0,
        },
        {
          name: this.$t('QuoteReady'),
          value: 'READY', 
          iconColor: 'warning',
          icon: 'fas fa-clock',
          number: 0,
        },
        {
          name: this.$t('QuoteDeclined'),
          value: 'DECLINED', 
          iconColor: 'error',
          icon: 'fas fa-times-circle',
          number: 0,
        },
        {
          name: this.$t('QuoteAccepted'),
          value: 'ACCEPTED', 
          iconColor: 'success',
          icon: 'fas fa-check-circle',
          number: 0,
        }
      ],
    };
    this.headers = [
      {
        text: this.$t('Status'),
        align: 'center',
        value: 'status',
        width: '100px',
        filter: (value, search, item) => {
          if(item.status === 'LOCKED' || item.status === 'READY') {
            if(item.dispatch.status === 'NEW' && this.statusFilter.includes('LOCKED')) {
              return true;
            }else if(item.dispatch.status === 'READY' && this.statusFilter.includes('READY')) {
              return true;
            }
          }else {
            if (this.statusFilter.length === 0) return true;
            if (this.statusFilter.includes(value) || (value === 'CLOSED' && this.statusFilter.includes('ACCEPTED'))) return true;          
          }
        }
      },
      {
        text: this.$t('QuoteNumber'),
        value: 'quoteNumber',
      },
      {
        text: this.$t('Brand'),
        value: 'brand.name',
      },
      {
        text: this.$t('VATExcludedPrice'),
        value: 'dispatch.purchase.totalAmountWithoutVAT',
        align: 'right',
      },
      { text: this.$t('Created'), value: 'created', align: 'center' },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
    this.reinitializeFilterCount();
  },
  updated() {
    this.reinitializeFilterCount();
  },
  methods: {
    reinitializeFilterCount() {
      /**
       * Reinitialize number
       */
      for(const key of Object.keys(this.filterObject)) {
        for(const filter of this.filterObject[key]) {
          filter.number = 0;
        }
      }

      this.customerQuotes.forEach(quote => {
        if(quote.status === 'LOCKED' || quote.status === 'READY') {
          if(quote.dispatch.status === 'NEW') {
            this.filterObject.status.find(x => x.value === 'LOCKED').number++;
          }else if(quote.dispatch.status === 'READY') {
            this.filterObject.status.find(x => x.value === 'READY').number++;
          }
        }else {
          this.filterObject.status.find(x => x.value === quote.status || (x.value === 'ACCEPTED' && quote.status === 'CLOSED')).number++;
        }
      });
    },
    customFilter(items, search, filter) {
      return CustomFilterForSearchDatatable.customFilter(items, search, this);
    },
    updateFilter(updatedFilter) {
      this.statusFilter = updatedFilter.status;
      if(updatedFilter.type) {
        this.typeFilter = updatedFilter.type;
      }
    },
    goToPageItem(item) {
      this.$router.push({name: 'Quote', params: { quoteUUID: `${item._id}`}});
    },
  },
};
</script>
