var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-customer-quotes"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', [_c('v-row', [_c('v-col', [_c('span', {
    staticClass: "text-wrap display-1"
  }, [_vm._v(_vm._s(_vm.$t('Quotes')))])])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', [_c('v-card-title', [_c('v-row', [_c('v-col', [_c('DataTableFilter', {
    ref: "dataTableFilter",
    attrs: {
      "width-of-filters-titles": "1",
      "filters": _vm.filterObject
    },
    on: {
      "update": _vm.updateFilter
    }
  })], 1), _c('v-col', {
    staticClass: "col-12 col-md-4"
  }, [_c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _c('v-card-text', [_c('v-data-table', {
    staticClass: "main-data-table",
    attrs: {
      "width": "100%",
      "headers": _vm.headers,
      "items": _vm.customerQuotes,
      "search": _vm.search,
      "custom-filter": _vm.customFilter,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    on: {
      "click:row": function clickRow($event) {
        return _vm.goToPageItem($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Quote',
              params: {
                quoteUUID: "".concat(item._id)
              }
            },
            "title": item.status !== 'CLOSED' ? _vm.$t(item.status) : _vm.$t('ACCEPTED')
          }
        }, [item.status === 'LOCKED' && item.dispatch.status === 'NEW' ? [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-unlock ")])] : item.status === 'LOCKED' && item.dispatch.status === 'READY' ? [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "warning"
          }
        }, [_vm._v(" fas fa-clock ")])] : item.status === 'DECLINED' ? [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "error"
          }
        }, [_vm._v(" fas fa-times-circle ")])] : item.status === 'ACCEPTED' || item.status === 'CLOSED' ? [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v(" fas fa-check-circle ")])] : _vm._e()], 2)];
      }
    }, {
      key: "item.quoteNumber",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Quote',
              params: {
                quoteUUID: "".concat(item._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.quoteNumber) + " ")])];
      }
    }, {
      key: "item.brand.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Quote',
              params: {
                quoteUUID: "".concat(item._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.brand.name) + " ")])];
      }
    }, {
      key: "item.dispatch.purchase.totalAmountWithoutVAT",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Quote',
              params: {
                quoteUUID: "".concat(item._id)
              }
            }
          }
        }, [item.billing ? _c('span', [_vm._v(" " + _vm._s(_vm.$n(item.dispatch.purchase.totalAmountWithoutVAT, 'currency', _vm.$userLocale)) + " ")]) : _c('span', {
          staticClass: "font-weight-light"
        }, [_vm._v(" " + _vm._s(_vm.$t('NotApplicable')) + " ")])])];
      }
    }, {
      key: "item.created",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Quote',
              params: {
                quoteUUID: "".concat(item._id)
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), 'long', _vm.$userLocale)) + " ")])];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Quote',
              params: {
                quoteUUID: "".concat(item._id)
              }
            }
          }
        }, [_c('v-btn', {
          staticClass: "primary",
          attrs: {
            "to": {
              name: 'Quote',
              params: {
                quoteUUID: "".concat(item._id)
              }
            },
            "title": _vm.$t('ViewQuote')
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" far fa-eye ")])], 1)], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }